<template>
  <div>
    <div class="col-12 col-md-3 mb-3 pl-md-0">
      <div class="d-block">
        <span class="font-14 font-weight-bold">{{ $t("filter") }}</span>
      </div>
      <ProductFilter v-bind:value="filter" v-on:input="onFilterChange" />
    </div>
    <template v-if="productList && productList.length">
      <transition-group name="list" mode="out-in" tag="div">
        <ProductCard
          v-for="(product, index) in productList"
          v-bind:key="product.id"
          v-bind:products.sync="productList"
          v-bind:index="index"
          v-on:add-competitor="onAddCompetitor"
          v-on:remove-product="onRemoveProduct"
          v-on:remove-competitor="onRemoveCompetitor"
          v-on:product-favorite="onFavoriteProduct"
        />
      </transition-group>
    </template>
    <template v-else-if="filter !== null">
      <WeCard class="alert-info">
        <i class="fas fa-info-circle mr-2"></i>
        <span class="font-weight-bold">{{ $t("productNotFound") }}</span>
      </WeCard>
    </template>
    <CompetitorModal
      v-if="showCompetitorModal"
      v-bind:product="singleProduct"
      v-on:close="onCloseCompetitor"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ProductFilter from "./components/ProductFilter/Index.vue";
const CompetitorModal = () => import("./components/AddCompetitor/Index.vue");
const ProductCard = () => import("./components/ProductCard/Index.vue");
export default {
  name: "PriceTable",
  components: {
    CompetitorModal,
    ProductCard,
    ProductFilter,
  },
  data() {
    return {
      data: [],
      filter: null,
      sort: null,
      showCompetitorModal: false,
    };
  },
  methods: {
    ...mapActions("priceAnalysis", [
      "removeCompetitor",
      "removeProduct",
      "favoriteProduct",
    ]),
    onAddCompetitor(index) {
      const product = this.productList[index];
      if (product) {
        this.singleProduct = product;
        this.showCompetitorModal = true;
      }
    },
    onCloseCompetitor(data = null) {
      this.showCompetitorModal = false;
      this.singleProduct = null;

      if (data) {
        this.onCreateCompetitor(data);
      }
    },
    onCreateCompetitor(data) {
      this.priceAnalysis.products = this.priceAnalysis.products.map(
        (product) => {
          if (product.id == data.productId) {
            const newCompetitor = this.newCompetitorData(data);
            product.competitors.push(newCompetitor);
          }
          return product;
        }
      );
    },
    newCompetitorData(data) {
      const newCompetitor = {
        id: data.productCompetitorId,
        name: data.name,
        url: data.url,
        tag: data.tag || null,
        favicon: data.favicon,
        availability: 0,
        updatedAt: data.updatedAt,
        loading: true,
        status: "pending",
      };
      return newCompetitor;
    },
    onRemoveCompetitor(data) {
      if (data && data.competitor_id) {
        this.$swal({
          title: this.$t("operationConfirmation"),
          text: this.$t("productCompetitorWillBeRemoved"),
          icon: "question",
          showCancelButton: true,
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("remove"),
        }).then((c) => {
          if (c.isConfirmed) {
            this.priceAnalysis.products = this.priceAnalysis.products.map(
              (item) => {
                if (item.id == data.product_id) {
                  if (item.competitors && item.competitors.length) {
                    const compIndex = item.competitors.findIndex(
                      (c) => c.id == data.competitor_id
                    );
                    if (compIndex >= 0) {
                      const competitor = item.competitors[compIndex];
                      this.removeCompetitor({
                        id: competitor.id,
                        onSuccess: (result) => {
                          this.$toast.success(
                            this.$t("competitorRemovedSuccessfully")
                          );
                          item.competitors.splice(compIndex, 1);
                        },
                      });
                    }
                  }
                }
                return item;
              }
            );
          }
        });
      }
    },
    onRemoveProduct(productId) {
      if (productId) {
        this.$swal({
          title: this.$t("operationConfirmation"),
          text: this.$t("productAndCompetitorRemove"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: this.$t("delete"),
        }).then((c) => {
          if (c.isConfirmed) {
            this.removeProduct({
              id: productId,
              onSuccess: (res) => {
                this.priceAnalysis.products =
                  this.priceAnalysis.products.filter(
                    (item) => item.id !== productId
                  );
                this.$toast.success(this.$t("productRemovedSuccessfully"));
              },
            });
          }
        });
      }
    },
    onFavoriteProduct(data) {
      this.favoriteProduct({
        id: data.id,
        status: data.status,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.priceAnalysis.products = this.priceAnalysis.products.map(
              (item) => {
                if (item.id == data.id) {
                  item.pinned = data.status;
                }
                return item;
              }
            );
            let toastMessage = this.$t("productAddedFavorite");
            if (!data.status) {
              toastMessage = this.$t("productRemovedFavorite");
            }
            this.$toast.success(toastMessage);
          }
        },
      });
    },
    onFilterChange(data) {
      this.filter = data;
    },
    filteredProducts(products) {
      const data = helper.clone(products);

      return data.filter((product) => {
        switch (this.filter) {
          // Kaydedilmiş Ürünler
          case "pinned":
            return product.pinned;

          // Rakibi olmayan ürünler
          case "onCompetitor":
            return !product.competitors.length;

          // Tüm Ürünler
          default:
            return product;
        }
      });
    },
    sortProducts(products) {
      return products.sort((a, b) => {
        switch (this.sort) {
          // Kaydetme sırasına göre
          default:
            return b.pinned - a.pinned;
        }
      });
    },
  },
  computed: {
    ...mapState({
      priceAnalysis: (state) => state.priceAnalysis,
      competitors: (state) => state.priceAnalysis.competitors,
    }),
    productList: {
      get() {
        return this.sortProducts(
          this.filteredProducts(this.priceAnalysis.products)
        );
      },
      set(value) {
        this.priceAnalysis.products = value;
        this.priceAnalysis.products = this.sortProducts(value);
      },
    },
  },
};
</script>
<style lang="scss">
.w-30 {
  width: 30%;
}
.list-move {
  transition: transform 1s;
}
</style>