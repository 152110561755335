<template>
  <v-select
    class="custom-v-select-sm"
    label="name"
    v-bind:options="getOptions"
    v-bind:value="value"
    v-bind:clearable="false"
    v-bind:reduce="(r) => r.id"
    v-on:input="onInput"
  ></v-select>
</template>
<script>
export default {
  name: "ProductFilter",
  props: {
    value: {
      default: null,
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    getOptions() {
      return [
        {
          id: null,
          name: this.$t("allProducts"),
        },
        {
          id: "pinned",
          name: this.$t("favoriteProducts"),
        },
      ];
    },
  },
};
</script>